<template>
  <div class="two-box">
    <div class="tabimg">
      <!-- <p>张凯凯</p> -->
      <div>
        <el-dropdown class="dropBox" trigger="click">
          <div class="title elp">
            {{ search.className }}<i class="iconfont icon-a-Group1895" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in classlist" :key="index">
              <div @click="getDetail(item)">
                {{ item.name }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!--     <el-select v-model="search.class" placeholder="请选择" @change="getDetail">
          <el-option
            v-for="(item, index) in classlist"
            :key="index"
            :label="item.name"
            :value="item.coursesId"
          >
          </el-option>
        </el-select> -->
        <div class="userTopRight">
          <div class="userTopCent">
            <div class="title">录播学习率</div>
            <div class="text">
              {{
                classIdDetails && classIdDetails.videoStudy
                  ? classIdDetails.videoStudy
                  : 0
              }}<span class="icon">%</span>
            </div>
          </div>
          <div class="userTopCents" />
          <div class="userTopCent">
            <div class="title">直播到课率</div>
            <div class="text">
              {{
                classIdDetails && classIdDetails.liveDKStudy
                  ? classIdDetails.liveDKStudy
                  : 0
              }}<span class="icon">%</span>
            </div>
          </div>
          <div class="userTopCents" />
          <div class="userTopCent">
            <div class="title">7天直播学习率</div>
            <div class="text">
              {{
                classIdDetails && classIdDetails.liveSevenStudy
                  ? classIdDetails.liveSevenStudy
                  : 0
              }}<span class="icon">%</span>
            </div>
          </div>
          <!-- <div class="userTopCents"></div>
          <div class="userTopCent">
            <div class="title">考场到考率</div>
            <div class="text">{{ classIdDetails.roomDkStudy ? classIdDetails.roomDkStudy : "0%" }}</div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="mycurriculum">
      <div id="gaga1" class="live-haveCar">
        <timetable />
      </div>
      <div class="fox">
        <div class="foxLeft">
          <div v-for="(item, index) in list" :key="index" class="foxLefta" @click="qiehuana(item)">
            <!-- <img class="img" :src="item.img" /> -->
            <span>{{ item.title }}</span>
            <div v-if="tabId == item.id" class="tips" />
          </div>
        </div>
        <!-- 我的课程 -->
        <div class="foxRight">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
// import Timetable from '@/views/mine/my/Timetable.vue'
import Timetable from '@/views/mine/my/Timetablesixth.vue'
import {
  selectPcStuClassIdDetails,
  getNoPastMClassCourseList,
  getPCClassRate
} from '@/api/mykecheng'
export default {
  components: { Timetable },
  data() {
    return {
      userInfo: null,
      userId: '',
      tabId: 1,
      list: [
        {
          id: 1,
          title: '我的课程',
          url: '/allsixth/mycurriculumsixth',
          icon: 'icon-wodekecheng',
          img: require('@/assets/img/learn/Group1.png')
        },
        {
          id: 4,
          title: '学习资料',
          url: '/allsixth/mydata',
          icon: 'icon-a-zu320',
          img: require('@/assets/img/learn/Group2.png')
        },

        {
          id: 3,
          title: '我的题库',
          url: '/allsixth/myexamination',
          icon: 'icon-a-zu319',
          img: require('@/assets/img/learn/Group3.png')
        },
        {
          id: 5,
          title: '我的考场',
          url: '/allsixth/myRoom',
          icon: 'icon-a-zu321',
          img: require('@/assets/img/learn/Group4.png')
        },
        {
          id: 7,
          title: '我的作业',
          url: '/allsixth/myOperation',
          icon: 'icon-a-zu321',
          img: require('@/assets/img/learn/Group4.png')
        },
        {
          id: 8,
          title: '每日一练',
          url: '/allsixth/practiceDaily',
          icon: 'icon-xuexijilu',
          img: require('@/assets/img/Home/daily/1.png')
        },
        {
          id: 2,
          title: '最近学习',
          url: '/allsixth/study',
          icon: 'icon-xuexijilu',
          img: require('@/assets/img/learn/Group5.png')
        },
        // {
        //   id: 6,
        //   title: '学习记录',
        //   url: '/allsixth/learningRecord',
        //   icon: 'icon-xuexijilu',
        //   img: require('@/assets/img/learn/studyRecord.png')
        // }
      ],
      classlist: [],
      search: {
        className: null,
        class: null
      },
      classIdDetails: {} // 学习率
    }
  },
  watch: {
    // 使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      if (oldVal.path == '/login' || newVal.path == '/login') {
        this.userId = localStorage.getItem('userId')
      }
    }
  },
  created() {
    this.userInfo = getInfo()
    this.userId = localStorage.getItem('userId')
    if (!this.userId) {
      Vue.prototype.goLoginView
      return
    }
    if (this.userInfo) {
      getNoPastMClassCourseList(this.userInfo.id, this.userInfo.tenantId).then(
        (res) => {
          this.classlist = res
          if (this.classlist && this.classlist.length > 0) {
            this.getDetail(res[0])
          }
        }
      )
    }
    for (const item of this.list) {
      if (this.$route.path == item.url) {
        this.tabId = item.id
        break
      }
    } // 滚动条监控  答题卡 滚动到顶部悬浮
    $(document).scroll(function () {
      // 滚动到上面  隐藏
      /*       $(window).scroll(function () {
        var box = document.getElementById("gaga1");
        var rect = box.getBoundingClientRect();
        var clientTop = document.documentElement.clientTop;
        let top = $(window).scrollTop();
        var windowHeight = $(this).height(); //获取浏览器窗口高度
        var scrollHeight = $(document).height(); //获取文档高度
        let bottom = rect.bottom - clientTop;
        if (top >= 130 && bottom > 320) {
          $(".live-haveCar").addClass("fixed-card");
        } else {
          $(".live-haveCar").removeClass("fixed-card");
        }
      }); */
    })
  },
  methods: {
    // 我的课程
    qiehuana(item) {
      if (this.tabId == item.id) {
        return
      }
      this.tabId = item.id
      this.$router.replace(item.url)
    },
    getDetail(item) {
      this.search.className = item.name
      this.search.class = item.type + ',' + item.coursesId
      /* 班型学习率 */
      /* let data = {
        studentId: this.userInfo.id,
        classesId: this.search.class,
      };*/
      const data = {
        userId: this.userInfo.id,
        classId: this.search.class,
        tenantId: this.userInfo.tenantId
      }
      /* selectPcStuClassIdDetails(data).then((res) => {
        this.classIdDetails = res.data;
      });*/
      getPCClassRate(data).then((res) => {

        this.classIdDetails = res.data
      })
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="less" scoped>
.two-box {
  position: relative;
}

.el-dropdown-menu {
  max-height: 300px !important;
  overflow-y: scroll !important;
}

.tabimg {
  width: 100%;
  height: 180px;
  background: url("~@/assets/img/homesixth/allBg.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: calc(50% - 600px);

  /deep/ .dropBox {
    margin-bottom: 16px;
    margin-top: 28px;

    .title {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 14px;

      .iconfont {
        font-size: 10px;
        color: #fff;
        margin-left: 8px;
      }
    }
  }

  // 右侧
  .userTopRight {
    width: auto;
    height: 45px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    // 斜线
    .userTopCents {
      width: 1px;
      height: 100%;
      background: #efefef;
      transform: rotate(20deg);
      margin: 0px 30px;
    }

    // 内容
    .userTopCent {
      height: 100%;
      width: max-content;
      padding: 0px;
      text-align: center;

      .title {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 14px;
      }

      .text {
        font-size: 24px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
        margin-top: 16px;
      }
    }
  }
}

.mycurriculum {
  width: 1220px;
  // background: #f5f7f9;
  margin: 0 auto;
  display: flex;
  position: relative;
  top: -40px;
  justify-content: space-between;

  border-radius: 8px 8px 8px 8px;

  .fox {
    width: 880px;
    // margin-right: 20px;
    // margin-left: calc(50% - 610px);
    min-height: 730px;

    border-radius: 8px 8px 8px 8px;

    .foxLeft {
      display: flex;
      width: 100%;
      height: 55px;
      border-bottom: 1px solid #eee;

      border-radius: 8px 8px 8px 8px;
      padding: 0px 24px;
      background: #ffffff;
      line-height: 55px;

      .foxLefta {
        // padding: 32px 0px 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        margin-right: 40px;
        position: relative;

        &:last-child {
          margin-right: 0px;
        }

        display: flex;
        justify-content: flex-start;
        align-items: center;

        .img {
          width: 32px;
          height: 32px;
        }

        .iconfont {
          font-size: 17px;
        }

        span {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }

        .tips {
          position: absolute;
          bottom: 0px;
          width: 20px;
          height: 3px;

          background: #1061ff;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
          left: calc(50% - 10px);
        }
      }

      .on {
        background: #fcefed;

        .foxLeftaL {
          background: #ff4027;
        }

        .foxLeftaR {
          span {
            color: #ff4027;
          }

          .iconfont {
            color: #ff4027;
          }
        }
      }
    }
  }

  /* 最近直播 */
  .live-haveCar {
    width: 320px;
    height: 560px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
  }
}

.foxRight {
  margin-bottom: 20px;
}

.zhicheng {
  width: 180px;
  flex-shrink: 0;
  height: 100%;
}

.fixed-card {
  position: fixed;
  top: 0px;
  right: calc(50% - 610px);
}
</style>
