<template>
  <div class="dailyQuestion">
    <div class="top">
      <img src="@/assets/img/Home/daily/question-icon.png" />
      <span>第{{ indexToChinese(index + 1) }}题</span>
      <div class="tips" v-if="info.questionType == 1">
        <img src="@/assets/img/Home/daily/question-tips.png" />
        <span>此题为多项选择题</span>
      </div>
    </div>
    <div class="container">
      <!-- 题目 -->
      <div ref="titleRef" class="title-main">
        <div :class="{ title: true, packTitle: isShowPack && !isUnfold }">
          <div v-html="ImgSizeLimit(info.questionTitle)"></div>
        </div>
        <template v-if="isShowPack">
          <div class="title-pack-mask" v-if="!isUnfold"></div>
          <div class="title-pack-but" @click="packBut">
            <span>{{ isUnfold ? "收起" : "展开" }}</span>
            <img
              src="@/assets/img/Home/daily/arrow-4.png"
              :class="{ unfoldImgUp: isUnfold }"
            />
          </div>
        </template>
      </div>

      <!-- 单选题 -->
      <div class="mt21 pb6" v-if="info.questionType == 0">
        <div
          :class="{
            'answer-option': true,
            'answer-userAnswer':
              !info.isShowAnswer &&
              info.questionUserAnswer == String.fromCharCode(65 + index),
            'answer-right':
              info.isShowAnswer &&
              info.questionAnswer == String.fromCharCode(65 + index),
            'answer-wrong':
              info.isShowAnswer &&
              info.questionUserAnswer == String.fromCharCode(65 + index) &&
              info.questionAnswer != String.fromCharCode(65 + index),
          }"
          v-for="(item, index) in info.questionOptionList"
          :key="index"
          @click="singleChoice(String.fromCharCode(65 + index))"
        >
          <div class="answer-i mr10 pointer">
            <el-icon class="answer-check"><Check /></el-icon>
            <el-icon class="answer-error"><Close /></el-icon>
          </div>
          <div class="answer-option-item pointer">
            {{ String.fromCharCode(65 + index) }}.
          </div>
          <div
            class="answer-option-item answer-option-item-content ml5 pointer"
            v-html="ImgSizeLimit(item)"
          ></div>
        </div>
      </div>

      <!-- 多选题、不定项选择 -->
      <div class="mt32 pb6" v-if="[1, 6].includes(info.questionType)">
        <div
          :class="{
            'answer-option': true,
            'answer-userAnswer':
              !info.isShowAnswer &&
              info.questionUserAnswer.includes(String.fromCharCode(65 + index)),
            'answer-right':
              info.isShowAnswer &&
              info.questionAnswer.includes(String.fromCharCode(65 + index)),
            'answer-wrong':
              info.isShowAnswer &&
              info.questionUserAnswer.includes(
                String.fromCharCode(65 + index)
              ) &&
              !info.questionAnswer.includes(String.fromCharCode(65 + index)),
          }"
          v-for="(item, index) in info.questionOptionList"
          :key="index"
          @click="multipleChoice(String.fromCharCode(65 + index))"
        >
          <div class="answer-i mr10 pointer">
            <el-icon class="answer-check"><Check /></el-icon>
            <el-icon class="answer-error"><Close /></el-icon>
          </div>
          <div class="answer-option-item pointer">
            {{ String.fromCharCode(65 + index) }}.
          </div>
          <div
            class="answer-option-item answer-option-item-content ml5 pointer"
            v-html="ImgSizeLimit(item)"
          ></div>
        </div>
      </div>

      <!-- 填空题、简答题 -->
      <div class="mt16" v-if="[3, 4].includes(info.questionType)">
        <div class="textarea-box">
          <el-input
            v-model="info.questionUserAnswer"
            placeholder="请输入您的答案~"
            resize="none"
            type="textarea"
            :maxlength="maxLength"
            class="inputi"
            :disabled="info.isShowAnswer"
            @input="inputAnswer"
          />
          <div class="wordLength" v-if="!info.isShowAnswer">
            <span>{{
              info.questionUserAnswer ? info.questionUserAnswer.length : 0
            }}</span>
            <span>/{{ maxLength }}</span>
          </div>
        </div>
        <div
          :class="{ 'imgs mt18': true, mb24: !info.isShowAnswer }"
          v-if="
            !info.isShowAnswer || (info.isShowAnswer && info.imgList.length)
          "
        >
          <div
            class="imgs-item"
            v-for="(item, index) in info.iList"
            :key="item"
          >
            <el-image
              :src="item"
              fit="cover"
              class="imgs-item-cover"
              :preview-src-list="info.iList"
              :initial-index="index"
            />
          </div>
          <el-upload
            v-if="!info.isShowAnswer"
            ref="upload"
            :action="imgAction()"
            :headers="Domainjwt"
            name="upload"
            :limit="9"
            :file-list="info.imgList"
            list-type="picture-card"
            :before-upload="beforeAvatarUpload"
            :on-success="
              (res, file, fileList) => {
                handleAvatarSuccess(info, res, file, fileList);
              }
            "
            :on-remove="
              (file, fileList) => {
                dialogRemove(info, file, fileList);
              }
            "
          >
            <i class="el-icon-plus" />
          </el-upload>

          <div class="imgs-item imgs-item-count" v-if="!info.isShowAnswer">
            <span>{{ info.imgList.length }}</span>
            <span>/{{ maxImgUpload }}</span>
          </div>
        </div>
      </div>
    </div>

    <template v-if="info.isShowAnswer">
      <!-- 客观题答案 -->
      <div class="answer" v-if="objective.includes(info.questionType)">
        <div
          :class="{
            'answer-item mr70': true,
            'answer-right': info.isRight,
            'answer-wrong': !info.isRight && info.isAnswer,
            'answer-noAnswer': !info.isAnswer,
          }"
        >
          <div class="answer-item-status"></div>
          <span>您的答案：</span>
          <span
            class="answer-item-userAnswer"
            v-if="[0, 2].includes(info.questionType)"
            >{{
              info.questionUserAnswer ? info.questionUserAnswer : "未做答"
            }}</span
          >
          <span
            class="answer-item-userAnswer"
            v-if="[1, 6].includes(info.questionType)"
            >{{
              info.questionUserAnswer.length != 0
                ? info.questionUserAnswer.toString()
                : "未做答"
            }}</span
          >
        </div>
        <div class="answer-item answer-right">
          <div class="answer-item-status"></div>
          <span>参考答案：</span>
          <span
            :class="{
              'answer-item-userAnswer': true,
              'subjective-answer-item-questionAnswer': subjective.includes(
                info.questionType
              ),
            }"
            v-html="ImgSizeLimit(info.questionAnswer)"
          ></span>
        </div>
      </div>

      <!-- 解析 -->
      <div
        :class="{
          analysis: true,
          'subjective-analysis': subjective.includes(info.questionType),
        }"
      >
        <div
          class="subjective-dashed"
          v-if="subjective.includes(info.questionType)"
        ></div>
        <div class="an-title">试题解析</div>
        <div class="an-content" v-html="ImgSizeLimit(info.questionAnswerAnalysis)"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { getInfo, getDomainToken } from "@/api/cookies";

// import { uploadFile } from "@/api/task/index";
export default {
  props: {
    index: {
      type: Number,
      default: -1,
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      judgeOption: ["正确", "错误"],
      maxLength: 1000,
      maxImgUpload: 9,
      objective: [0, 1, 2, 6],
      subjective: [3, 4, 5],
      titleRef: null,
      isShowPack: false,
      isUnfold: false,
      Domainjwt: {
        Domainjwt: getDomainToken(),
      },
    };
  },
  watch: {
    index: {
      immediate: true,
      handler(newValue) {
        this.checkHeight();
      },
    },
  },
  methods: {
    checkHeight() {
      this.$nextTick(() => {
        setTimeout(() => {
          const height = this.$refs.titleRef.offsetHeight;
          this.isShowPack = height > 170;
        }, 1000);
      });
    },
    indexToChinese(index) {
      // 这里需要实现数字转汉字的逻辑
      return this.numberToChinese(index);
    },
    numberToChinese(num) {
      var units = ["", "十", "百", "千", "万"];
      var nums = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
      var str = ("" + num).split("");
      var result = "";

      for (var i = 0; i < str.length; i++) {
        var n = str[i];
        var unit = units[str.length - i - 1];
        result += nums[parseInt(n)] + unit;
      }

      result = result.replace(/零(千|百|十)/g, "零").replace(/零+/g, "零");
      result = result.replace(/零万/g, "万");
      result = result.replace(/零亿/g, "亿");
      result = result.replace(/零零/g, "零");
      result = result.replace(/亿万/g, "亿");
      result = result.replace(/零+$/, "");
      result = result.replace(/^一十/g, "十");

      return result;
    },
    packBut() {
      this.isUnfold = !this.isUnfold;
    },
    singleChoice(value) {
      this.$emit("answer", {
        value,
        index: this.index,
      });
    },
    multipleChoice(value) {
      this.$emit("answer", {
        value,
        index: this.index,
      });
    },
    inputAnswer(value) {
      this.$emit("answer", {
        value,
        index: this.index,
        type: "text",
      });
    },
    uploadImg() {
      this.$refs.fileInput.value = "";
      this.$refs.fileInput.click();
    },
    async fileChange(e) {
      const files = Array.from(e.target.files).filter((i) =>
        i.type.includes("image")
      );
      const arr = files.slice(0, this.maxImgUpload - this.info.imgList.length);
      let img = [];
      for (const item of arr) {
        img.push(await this.uploadFile(item));
      }
      this.$emit("answer", {
        value: img,
        index: this.index,
        type: "uploadImg",
      });
    },
    delImg(index) {
      this.$emit("answer", {
        value: index,
        index: this.index,
        type: "delImg",
      });
    },
    beforeAvatarUpload(file) {
      const allowImgType = ["image/jpeg", "image/png", "image/gif"];
      if (!allowImgType.includes(file.type)) {
        this.$message.error("上传文件类型不符,仅支持jpg/png/gif格式图片");
        return false;
      }
    },

    // 图片上传
    handleAvatarSuccess(info, res, file, fileList) {
      console.log(res, "res");
      console.log(info, "info");

      if (res.isSuccess) {
        if (!info.imgList) {
          info.imgList = [];
        }
        const data = { url: res.path, path: "" };
        info.imgList.push(data);
      }
    },
    dialogRemove(info, file, fileList) {
      console.log("删除成功");
      info.imgList = info.imgList.filter((item) => {
        return item.url != file.response.path;
      });
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/questionBank/optionList.scss";
@import "@/style/questionBank/answer.scss";

.dailyQuestion {
  border-radius: 10px;
  box-shadow: 0px 0px 6px 1px rgba(204, 204, 204, 0.3);
  overflow: hidden;
  .top {
    width: 100%;
    height: 50px;
    background-color: #f6f6f6;
    font-size: 16px;
    color: #1C81F5;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 26px;
    position: relative;
    img {
      width: 17px;
      height: 18px;
      margin-right: 6px;
    }
    .tips {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #ff5050;
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      img {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        transform: translateY(1px);
      }
    }
  }
  .container {
    padding: 0 40px;
  }
  .title-main {
    position: relative;
    padding: 0 2px;
  }
  .title {
    font-size: 16px;
    color: #333;
  }
  .title-img {
    width: 256px;
    height: 144px;
    margin-top: 12px;
    display: block;
    border-radius: 8px;
  }
  .title-audio {
    margin: 20px 0;
  }
  .title-video {
    width: 100%;
    height: 300px;
    margin: 20px 0;
  }
  .packTitle {
    height: 170px;
    overflow: hidden;
  }
  .title-pack-mask {
    width: 102%;
    height: 35px;
    background-color: #fff;
    filter: blur(3px);
    position: absolute;
    bottom: 35px;
    left: -2px;
  }
  .title-pack-but {
    width: 130px;
    height: 36px;
    margin: 6px auto 0;
    background-color: #f7f7fa;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #777;
    cursor: pointer;
    img {
      width: 11px;
      height: 7px;
      margin-left: 8px;
    }
    .unfoldImgUp {
      transform: rotate(180deg);
    }
  }
}
.inputi {
  height: 88px;
  :deep(textarea) {
    color: #777 !important;
  }
}
.imgs {
  display: flex;
  .imgs-item {
    width: 74px;
    height: 74px;
    margin-right: 12px;
    position: relative;
    cursor: pointer;
    .imgs-item-cover {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
    .img-del {
      width: 16px;
      height: 16px;
      position: absolute;
      top: -8px;
      right: -8px;
    }
  }
  .imgs-item-count {
    width: 50px;
    font-size: 12px;
    color: #aaa;
    display: flex;
    align-items: flex-end;
    margin-right: 0;margin-left: 10px;
    cursor: default;
    span:first-child {
      color: #1C81F5;
    }
  }
}
.answer {
  border-top: 1px dashed #eee;
  width: 100%;
  height: 66px;
  background-color: #f9fbff;
  padding-left: 43px;
  display: flex;
  align-items: center;
}
.analysis {
  padding-top: 20px;
  padding-bottom: 18px;
  padding-left: 59px;
  padding-right: 52px;
  .an-title {
    font-size: 14px;
    color: #333;
    font-weight: 600;
    position: relative;
    margin-bottom: 10px;
    &::before {
      content: "";
      width: 4px;
      height: 12px;
      background-color: #1C81F5;
      border-radius: 2px;
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .an-content {
    font-size: 14px;
    color: #777;
    line-height: 26px;
  }
}
.subjective-analysis {
  padding-top: 17px;
  padding-left: 40px;
  padding-right: 40px;
  .an-title {
    padding-left: 10px;
    &::before {
      left: 0px;
    }
  }
  .subjective-dashed {
    border-top: 1px dashed #eee;
    margin-bottom: 17px;
  }
}
::v-deep .el-textarea__inner {
  border: 0 !important;
}
::v-deep .el-upload-list__item {
  width: 74px;
  height: 74px;
}
::v-deep .el-upload {
  width: 74px;
  height: 74px;
}
::v-deep .el-upload--picture-card {
  line-height: 74px;
}
</style>
