<template>
  <!-- 每日一练做题页 -->
  <div class="dailyQuestion" v-loading="loading">
    <!-- 顶部 -->
    <div class="top">
      <div class="back" @click="back">
        <img src="@/assets/img/Home/daily/7.png" />
        <!-- <span>返回</span> -->
      </div>
      <div class="title oneEllipsis">{{ belongCourseTitleName }}</div>
      <div
        class="but pointer"
        @click="showSubmit"
        v-if="list.length && !isSubmit"
      >
        <img src="@/assets/img/Home/daily/submit-icon.png" />
        <span>提交练习</span>
      </div>
      <div class="time" v-if="isSubmit">
        <img src="@/assets/img/Home/daily/time-1.png" class="time-icon" />
        <span>预计用时：</span>
        <span style="color: #2871ff" class="mr28">{{ expectTime }}</span>
        <img src="@/assets/img/Home/daily/time-1.png" class="time-icon" />
        <span>实际练习时长：</span>
        <span style="color: #2871ff">{{ usedTime }}</span>
      </div>
    </div>

    <!-- 题目 -->
    <div class="question">
      <!-- 时间 -->
     
      <div class="mb20" v-for="(item, index) in list" :key="index">
        <dailyQuestion :index="index" :info="item" @answer="answer" />
      </div>
    </div>
    <!-- 交卷提示 -->
    <div class="mask-fixed submit" v-if="submitTips">
      <div class="submit-box">
        <!-- 未做完 -->
        <div class="submit-title" v-if="number.notDo != 0">
          <span>您还有</span>
          <span style="color: #1c81f5">{{ number.notDo }}道</span>
          <span>试题未作答，是否提交？</span>
        </div>
        <!-- 全部做完 -->
        <div class="submit-title" v-else>您已完成全部试题，是否提交？</div>
        <!-- 按钮 -->
        <div class="submit-but">
          <div class="submit goon" @click="showSubmit">继续做题</div>
          <el-button
            class="submit sub"
            @click="submit"
            :loading="submitLoading"
            :disabled="submitLoading"
            >确定提交</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, reactive, onBeforeUnmount } from "vue";
import { Know } from "@/api/know";
const know = new Know();
// import { setStyleFixed, exitStyleFixed } from "@/utils/public";

// import { Study } from "@/api/studyClient";
// const studyApi = new Study();
// import { ExamClient } from "@/api/examClient";
// const examApi = new ExamClient();

import dailyQuestion from "./dailyQuestionSix.vue";
export default {
  components: { dailyQuestion },

  data() {
    return {
      belongCourseTitleId: "",
      belongCourseTitleName: "",
      day: "",
      type: "", // 1做题2解析
      expectTime: "00:00", // 预计用时
      usedTime: "00:00", // 实际用时
      list: [],
      isSubmit: false, // 是否已交卷
      number: {
        total: 0,
        do: 0,
        notDo: 0,
      },
      loading: false, // 加载
      timer: null, // 答题定时器
      time: 0, // 秒数
      submitTips: false,
      submitLoading: false,
    };
  },
  created() {
    this.belongCourseTitleId = this.$route.query.belongCourseTitleId;
    this.belongCourseTitleName = this.$route.query.belongCourseTitleName;
    this.day = this.$route.query.day;
    this.type = this.$route.query.type;
    this.expectTime = this.$route.query.expectTime;
    // if (this.$route.query.expectTime) {
    //   const eTime = this.timeDiff(this.$route.query.expectTime * 60);
    //   console.log(eTime,'eTime');

    //   this.expectTime = `${eTime.h != "00" ? eTime.h + ":" : ""}${eTime.m}:${
    //     eTime.s
    //   }`;
    //   console.log(this.expectTime,'this.expectTime');

    // }
    if (this.type == 1) {
      this.getList();
    } else {
      this.getAnalysis();
    }
  },
  beforeDestroy() {
    this.clearTimer();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    setTimer() {
      this.clearTimer();
      this.timer = setInterval(() => {
        this.time++;
      }, 1000);
    },
    clearTimer() {
      clearInterval(this.timer);
    },
    getList() {
      this.loading = true;
      know
        .selectPracticeQuesSubject({
          courseId: this.belongCourseTitleId,
          practiceDay: this.day,
        })
        .then((res) => {
          this.list = this.setData(res.data);
          this.number.total = this.list.length;
          this.setTimer();
          this.loading = false;
        });
    },
    setData(arr) {
      arr.forEach((item) => {
        item.questionType = Number(item.questionType);
        item.questionUserAnswer = "";
        item.imgList = [];
        if ([1, 6].includes(item.questionType)) {
          item.questionUserAnswer = [];
        }
        if (!item.questionOptionList) {
          item.questionOptionList = JSON.parse(item.questionOption);
        }
      });
      return arr;
    },
    getAnalysis() {
      this.loading = true;

      know
        .selectPracticeQuesByResultTime({
          courseId: this.belongCourseTitleId,
          practiceDay: this.day,
        })
        .then((res) => {
          this.usedTime = res.data.transfom;
        });
      know
        .selectPracticeQuesByResultId({
          courseId: this.belongCourseTitleId,
          practiceDay: this.day,
        })
        .then((res) => {
          this.isSubmit = true;

          this.list = this.setAnalysisList(res.data);
          this.loading = false;
        });
    },
    setAnalysisList(arr) {
      arr.forEach((item) => {
        item.isShowAnswer = true;
        item.questionType = Number(item.questionType);
        // 单选、判断
        if ([0, 2].includes(item.questionType)) {
          if (item.questionUserAnswer) {
            item.isRight = item.questionAnswer == item.questionUserAnswer;
            item.isAnswer = true;
          } else {
            item.isRight = false;
            item.isAnswer = false;
          }
        }
        // 多选、不定项
        if ([1, 6].includes(item.questionType)) {
          if (item.questionUserAnswer) {
            item.isRight = item.questionAnswer == item.questionUserAnswer;
            item.isAnswer = true;
            item.questionUserAnswer = item.questionUserAnswer
              .split(",")
              .filter((i) => i);
          } else {
            item.isRight = false;
            item.isAnswer = false;
            item.questionUserAnswer = [];
          }
        }
        item.imgList = [];
        if ([3, 4].includes(item.questionType) && item.questionUserAnswerImg) {
          item.iList = JSON.parse(item.questionUserAnswerImg);
          let arr = [];
          item.iList.forEach((e) => {
            arr.push({ url: e.url, path: "" });
          });
          item.imgList = arr; 
        }
        if (!item.questionOptionList) {
          item.questionOptionList = JSON.parse(item.questionOption);
        }
      });
      return arr;
    },
    answer(data) {
      if (this.isSubmit) return;
      const info = this.list[data.index];
      if ([0, 2].includes(info.questionType)) {
        // 单选题、判断题
        info.questionUserAnswer = data.value;
      }
      if ([1, 6].includes(info.questionType)) {
        // 多选题、不定项选择
        if (!Array.isArray(info.questionUserAnswer)) {
          info.questionUserAnswer = info.questionUserAnswer
            ? info.questionUserAnswer.split(",")
            : [];
        }
        const idx = info.questionUserAnswer.findIndex((i) => i == data.value);
        idx == -1
          ? info.questionUserAnswer.push(data.value)
          : info.questionUserAnswer.splice(idx, 1);
      }
      if ([3, 4].includes(info.questionType)) {
        // 填空题、简答题
        if (data.type == "text") {
          info.questionUserAnswer = data.value;
        } else if (data.type == "delImg") {
          // 删除图片
          info.imgList.splice(data.value, 1);
        } else if (data.type == "uploadImg") {
          info.imgList = info.imgList.concat(data.value);
        }
      }
    },
    showSubmit() {
      if (this.submitLoading) return;
      this.submitTips = !this.submitTips;
    },
    submit() {
      this.submitLoading = true;
      this.clearTimer();
      know
        .addPracticeQuesResult({
          courseId: this.belongCourseTitleId,
          practiceDay: this.day,
          problemTime: this.time,
          subjectDtoList: this.list.map((item) => {
            if ([1, 6].includes(item.questionType)) {
              item.questionUserAnswer = item.questionUserAnswer
                .sort()
                .join(",");
            }
            if (item.imgList.length) {
              let arr = [];
              item.imgList.forEach((e) => {
                arr.push(e.url);
              });
              item.questionUserAnswerImg = JSON.stringify(arr);
            } else {
              item.questionUserAnswerImg = null;
            }
            return item;
          }),
        })
        .then((res) => {
          this.submitLoading = false;
          if (res.code == 0) {
            this.getAnalysis();
            this.showSubmit();
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    timeDiff(time, isShowHour = true) {
      if (!time) {
        return {
          h: "00",
          m: "00",
          s: "00",
        };
      }

      let h = 0;
      let m = 0;
      if (time >= 60) {
        m = parseInt(time / 60 + "");
        time = parseInt((time % 60) + "");
        if (m >= 60 && isShowHour) {
          h = parseInt(m / 60 + "");
          m = parseInt((m % 60) + "");
        }
      }
      return {
        h: this.setZero(h),
        m: this.setZero(m),
        s: this.setZero(time),
      };
    },
    setZero(value) {
      return value < 10 ? "0" + value : value;
    },
    exitStyleFixed() {
      const body = document.getElementsByTagName("body")[0];
      body.setAttribute("style", "zoom: 1");

      const app = document.getElementById("app");
      app.setAttribute("style", "");
    },
    setStyleFixed() {
      const body = document.getElementsByTagName("body")[0];
      const bodyStyleContent = body.getAttribute("style");
      body.setAttribute(
        "style",
        bodyStyleContent ? bodyStyleContent + bodyStyle : bodyStyle
      );

      const app = document.getElementById("app");
      const appStyleContent = app.getAttribute("style");
      app.setAttribute(
        "style",
        appStyleContent ? appStyleContent + appStyle : appStyle
      );
    },
  },
  watch: {
    submitTips: {
      handler(newVal) {
        if (newVal) {
          this.number.do = 0;
          this.list.forEach((item) => {
            if ([0, 2].includes(item.questionType) && item.questionUserAnswer) {
              // 单选题、判断题
              this.number.do++;
            } else if (
              [1, 6].includes(item.questionType) &&
              item.questionUserAnswer.length
            ) {
              // 多选题、不定项题
              this.number.do++;
            } else if (
              [3, 4].includes(item.questionType) &&
              (item.questionUserAnswer || item.imgList.length)
            ) {
              // 简答题
              this.number.do++;
            }
          });
          this.number.notDo = this.number.total - this.number.do;
          this.setStyleFixed();
        } else {
          this.exitStyleFixed();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.dailyQuestion {
  height: 100%;
  // border-radius: 8px;
  overflow-x: hidden;
  background: #fff;
  display: flex;
  flex-direction: column;
  .top {
    width: 100%;
    height: 70px;
    // box-shadow: 0px 3px 12px 1px rgba(204, 204, 204, 0.3);
    padding: 0 24px;
    display: flex;
    align-items: center;
    .back {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #777;
      cursor: pointer;
      width: 22px;
      height: 34px;
      background: #CDEAFC;
      border-radius: 11px 11px 11px 11px;

      img {
        display: block;
        width: 9px;
        height: 14px;
        // margin-right: 8px;
        // transform: translateY(2px);
      }
    }
    .title {
      flex: 1;
      color: #333;
      font-size: 20px;
      font-weight: bold;
      text-align: left;
      margin: 0 20px;
    }
    .but {
      width: 134px;
      height: 45px;
      border-radius: 24px;
      // background: linear-gradient(180deg, #97b2ff 0%, #3067ff 100%);
      background: #1C81F5;
      font-size: 15px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 13px;
        height: 15px;
        margin-right: 6px;
      }
    }
  }
}
.time {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: #777;
  // margin-bottom: 20px;
  .time-icon {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
}
.question {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  padding-top: 10px;
}
.submit {
  .submit-box {
    width: 374px;
    height: 152px;
    border-radius: 12px;
    background-color: #fff;
    .submit-title {
      font-size: 20px;
      color: #333;
      text-align: center;
      margin: 28px 0 32px;
    }
    .submit-but {
      display: flex;
      align-items: center;
      justify-content: center;
      .submit {
        width: 120px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        text-align: center;
        font-size: 16px;
        // font-weight: bold;
        margin: 0 10px;
        cursor: pointer;
      }
      .goon {
        background-color: #fff;
        border: 1px solid #1C81F5;
        color: #1C81F5;
      }
      .sub {
        background-color: #1C81F5;
        color: #fff;
        border: none;
      }
    }
  }
}
</style>
