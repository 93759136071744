var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dailyQuestion"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("@/assets/img/Home/daily/question-icon.png")}}),_c('span',[_vm._v("第"+_vm._s(_vm.indexToChinese(_vm.index + 1))+"题")]),(_vm.info.questionType == 1)?_c('div',{staticClass:"tips"},[_c('img',{attrs:{"src":require("@/assets/img/Home/daily/question-tips.png")}}),_c('span',[_vm._v("此题为多项选择题")])]):_vm._e()]),_c('div',{staticClass:"container"},[_c('div',{ref:"titleRef",staticClass:"title-main"},[_c('div',{class:{ title: true, packTitle: _vm.isShowPack && !_vm.isUnfold }},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.ImgSizeLimit(_vm.info.questionTitle))}})]),(_vm.isShowPack)?[(!_vm.isUnfold)?_c('div',{staticClass:"title-pack-mask"}):_vm._e(),_c('div',{staticClass:"title-pack-but",on:{"click":_vm.packBut}},[_c('span',[_vm._v(_vm._s(_vm.isUnfold ? "收起" : "展开"))]),_c('img',{class:{ unfoldImgUp: _vm.isUnfold },attrs:{"src":require("@/assets/img/Home/daily/arrow-4.png")}})])]:_vm._e()],2),(_vm.info.questionType == 0)?_c('div',{staticClass:"mt21 pb6"},_vm._l((_vm.info.questionOptionList),function(item,index){return _c('div',{key:index,class:{
          'answer-option': true,
          'answer-userAnswer':
            !_vm.info.isShowAnswer &&
            _vm.info.questionUserAnswer == String.fromCharCode(65 + index),
          'answer-right':
            _vm.info.isShowAnswer &&
            _vm.info.questionAnswer == String.fromCharCode(65 + index),
          'answer-wrong':
            _vm.info.isShowAnswer &&
            _vm.info.questionUserAnswer == String.fromCharCode(65 + index) &&
            _vm.info.questionAnswer != String.fromCharCode(65 + index),
        },on:{"click":function($event){_vm.singleChoice(String.fromCharCode(65 + index))}}},[_c('div',{staticClass:"answer-i mr10 pointer"},[_c('el-icon',{staticClass:"answer-check"},[_c('Check')],1),_c('el-icon',{staticClass:"answer-error"},[_c('Close')],1)],1),_c('div',{staticClass:"answer-option-item pointer"},[_vm._v(" "+_vm._s(String.fromCharCode(65 + index))+". ")]),_c('div',{staticClass:"answer-option-item answer-option-item-content ml5 pointer",domProps:{"innerHTML":_vm._s(_vm.ImgSizeLimit(item))}})])}),0):_vm._e(),([1, 6].includes(_vm.info.questionType))?_c('div',{staticClass:"mt32 pb6"},_vm._l((_vm.info.questionOptionList),function(item,index){return _c('div',{key:index,class:{
          'answer-option': true,
          'answer-userAnswer':
            !_vm.info.isShowAnswer &&
            _vm.info.questionUserAnswer.includes(String.fromCharCode(65 + index)),
          'answer-right':
            _vm.info.isShowAnswer &&
            _vm.info.questionAnswer.includes(String.fromCharCode(65 + index)),
          'answer-wrong':
            _vm.info.isShowAnswer &&
            _vm.info.questionUserAnswer.includes(
              String.fromCharCode(65 + index)
            ) &&
            !_vm.info.questionAnswer.includes(String.fromCharCode(65 + index)),
        },on:{"click":function($event){_vm.multipleChoice(String.fromCharCode(65 + index))}}},[_c('div',{staticClass:"answer-i mr10 pointer"},[_c('el-icon',{staticClass:"answer-check"},[_c('Check')],1),_c('el-icon',{staticClass:"answer-error"},[_c('Close')],1)],1),_c('div',{staticClass:"answer-option-item pointer"},[_vm._v(" "+_vm._s(String.fromCharCode(65 + index))+". ")]),_c('div',{staticClass:"answer-option-item answer-option-item-content ml5 pointer",domProps:{"innerHTML":_vm._s(_vm.ImgSizeLimit(item))}})])}),0):_vm._e(),([3, 4].includes(_vm.info.questionType))?_c('div',{staticClass:"mt16"},[_c('div',{staticClass:"textarea-box"},[_c('el-input',{staticClass:"inputi",attrs:{"placeholder":"请输入您的答案~","resize":"none","type":"textarea","maxlength":_vm.maxLength,"disabled":_vm.info.isShowAnswer},on:{"input":_vm.inputAnswer},model:{value:(_vm.info.questionUserAnswer),callback:function ($$v) {_vm.$set(_vm.info, "questionUserAnswer", $$v)},expression:"info.questionUserAnswer"}}),(!_vm.info.isShowAnswer)?_c('div',{staticClass:"wordLength"},[_c('span',[_vm._v(_vm._s(_vm.info.questionUserAnswer ? _vm.info.questionUserAnswer.length : 0))]),_c('span',[_vm._v("/"+_vm._s(_vm.maxLength))])]):_vm._e()],1),(
          !_vm.info.isShowAnswer || (_vm.info.isShowAnswer && _vm.info.imgList.length)
        )?_c('div',{class:{ 'imgs mt18': true, mb24: !_vm.info.isShowAnswer }},[_vm._l((_vm.info.iList),function(item,index){return _c('div',{key:item,staticClass:"imgs-item"},[_c('el-image',{staticClass:"imgs-item-cover",attrs:{"src":item,"fit":"cover","preview-src-list":_vm.info.iList,"initial-index":index}})],1)}),(!_vm.info.isShowAnswer)?_c('el-upload',{ref:"upload",attrs:{"action":_vm.imgAction(),"headers":_vm.Domainjwt,"name":"upload","limit":9,"file-list":_vm.info.imgList,"list-type":"picture-card","before-upload":_vm.beforeAvatarUpload,"on-success":(res, file, fileList) => {
              _vm.handleAvatarSuccess(_vm.info, res, file, fileList);
            },"on-remove":(file, fileList) => {
              _vm.dialogRemove(_vm.info, file, fileList);
            }}},[_c('i',{staticClass:"el-icon-plus"})]):_vm._e(),(!_vm.info.isShowAnswer)?_c('div',{staticClass:"imgs-item imgs-item-count"},[_c('span',[_vm._v(_vm._s(_vm.info.imgList.length))]),_c('span',[_vm._v("/"+_vm._s(_vm.maxImgUpload))])]):_vm._e()],2):_vm._e()]):_vm._e()]),(_vm.info.isShowAnswer)?[(_vm.objective.includes(_vm.info.questionType))?_c('div',{staticClass:"answer"},[_c('div',{class:{
          'answer-item mr70': true,
          'answer-right': _vm.info.isRight,
          'answer-wrong': !_vm.info.isRight && _vm.info.isAnswer,
          'answer-noAnswer': !_vm.info.isAnswer,
        }},[_c('div',{staticClass:"answer-item-status"}),_c('span',[_vm._v("您的答案：")]),([0, 2].includes(_vm.info.questionType))?_c('span',{staticClass:"answer-item-userAnswer"},[_vm._v(_vm._s(_vm.info.questionUserAnswer ? _vm.info.questionUserAnswer : "未做答"))]):_vm._e(),([1, 6].includes(_vm.info.questionType))?_c('span',{staticClass:"answer-item-userAnswer"},[_vm._v(_vm._s(_vm.info.questionUserAnswer.length != 0 ? _vm.info.questionUserAnswer.toString() : "未做答"))]):_vm._e()]),_c('div',{staticClass:"answer-item answer-right"},[_c('div',{staticClass:"answer-item-status"}),_c('span',[_vm._v("参考答案：")]),_c('span',{class:{
            'answer-item-userAnswer': true,
            'subjective-answer-item-questionAnswer': _vm.subjective.includes(
              _vm.info.questionType
            ),
          },domProps:{"innerHTML":_vm._s(_vm.ImgSizeLimit(_vm.info.questionAnswer))}})])]):_vm._e(),_c('div',{class:{
        analysis: true,
        'subjective-analysis': _vm.subjective.includes(_vm.info.questionType),
      }},[(_vm.subjective.includes(_vm.info.questionType))?_c('div',{staticClass:"subjective-dashed"}):_vm._e(),_c('div',{staticClass:"an-title"},[_vm._v("试题解析")]),_c('div',{staticClass:"an-content",domProps:{"innerHTML":_vm._s(_vm.ImgSizeLimit(_vm.info.questionAnswerAnalysis))}})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }