<template>
  <div id="app">
    <!-- <layout></layout> -->
    <router-view></router-view>
  </div>
</template>

<script>
import { getDnsOneApi } from "@/api/home";
import { addBrowse } from "@/api/login";
import { getInfo } from "@/api/cookies";
import { selectEntrantsSeoPcList } from "@/api/home";
import {
  setDomain,
  setDomainToken,
  setDomainLogo,
  setDomainInfo,
} from "@/api/cookies";
export default {
  // components: {layout,},
  data() {
    return {
      ifshow: false,
    };
  },
  watch: {
    //使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    $route: function (newVal, oldVal) {
      let userInfo = getInfo();
      this.activeIndex = newVal.path;
      addBrowse(newVal.name, newVal.path, userInfo ? userInfo.id : undefined);
    },
  },
  async created() {
    if (this.$route.query.inviterId) {
      sessionStorage.setItem("inviterId", this.$route.query.inviterId);
      if (this.$route.query.teacherId) {
        sessionStorage.setItem("inviterTeacherId", this.$route.query.teacherId);
      }
    }

    await getDnsOneApi().then(async (res) => {
      if (res.code == 200) {
        // document.title = res.msg.entrants.name ? res.msg.entrants.name : ''
        /* Vue-cli3的favicon修改问题 */
        var link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = res.msg.entrants.cornerMark;
        document.getElementsByTagName("head")[0].appendChild(link);
        setDomainToken(res.msg.domainToken);
        setDomainLogo(res.msg.entrants.logo);
        // res.msg.entrants.eId = 382
        // res.msg.entrants.entrantsType = 2;
        setDomain(res.msg.entrants);
        localStorage.setItem('isXiaoQingWang', res.msg.xiaoQingId)
        setDomainInfo(res.msg.entrants.copyright);
        let data = this.checkMobile();
        if (data) {
          window.open(
            res.msg.dns
              ? res.msg.dns.mDuliDomain
                ? res.msg.dns.mDuliDomain
                : res.msg.dns.useMDomain
              : "",
            "_self"
          );
        }
      } else {
        this.$router.push("/OffShelf");
      }
    });
  },
  methods: {
    checkMobile() {
      var pda_user_agent_list = new Array(
        "2.0 MMP",
        "240320",
        "AvantGo",
        "BlackBerry",
        "Blazer",
        "Cellphone",
        "Danger",
        "DoCoMo",
        "Elaine/3.0",
        "EudoraWeb",
        "hiptop",
        "IEMobile",
        "KYOCERA/WX310K",
        "LG/U990",
        "MIDP-2.0",
        "MMEF20",
        "MOT-V",
        "NetFront",
        "Newt",
        "Nintendo Wii",
        "Nitro",
        "Nokia",
        "Opera Mini",
        "Opera Mobi",
        "Palm",
        "Playstation Portable",
        "portalmmm",
        "Proxinet",
        "ProxiNet",
        "SHARP-TQ-GX10",
        "Small",
        "SonyEricsson",
        "Symbian OS",
        "SymbianOS",
        "TS21i-10",
        "UP.Browser",
        "UP.Link",
        "Windows CE",
        "WinWAP",
        "Android",
        "iPhone",
        "iPod",
        "iPad",
        "Windows Phone",
        "HTC" /*, "GTB"*/
      );
      var pda_app_name_list = new Array("Microsoft Pocket Internet Explorer");
      var user_agent = navigator.userAgent.toString();
      for (var i = 0; i < pda_user_agent_list.length; i++) {
        if (user_agent.indexOf(pda_user_agent_list[i]) >= 0) {
          return true;
        }
      }
      var appName = navigator.appName.toString();
      for (var i = 0; i < pda_app_name_list.length; i++) {
        if (user_agent.indexOf(pda_app_name_list[i]) >= 0) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  background: #f5f7f9;
}

@font-face {
  font-family: DIN-Regular;
  /* 粗体 */
  src: url("./assets/fontFamily/DIN Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Source Han Sans CN BOLD;
  /* 粗体 */
  src: url("./assets/fontFamily/SOURCEHANSANSCN-BOLD-2.OTF");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Source Han Sans CN EXTRALIGHT;
  /* 超轻型 */
  src: url("./assets/fontFamily/SOURCEHANSANSCN-EXTRALIGHT-2.OTF");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Source Han Sans CN MEDIUM;
  /* 中等 */
  src: url("./assets/fontFamily/SOURCEHANSANSCN-MEDIUM-2.OTF");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Source Han Sans CN;
  /* 正常 */
  src: url("./assets/fontFamily/SOURCEHANSANSCN-NORMAL.OTF");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Source Han Sans CN REGULAR;
  /* 常规 */
  src: url("./assets/fontFamily/SOURCEHANSANSCN-REGULAR-2.OTF");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: DIN;
  src: url("./assets/fontFamily/DIN Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: DIN BOLD;
  src: url("./assets/fontFamily/din-bold.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: DIN Regular;
  src: url("./assets/fontFamily/DIN Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: PINGFANG BOLD;
  src: url("./assets/fontFamily/PINGFANG BOLD.TTF");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: PINGFANG MEDIUM;
  src: url("./assets/fontFamily/PINGFANG MEDIUM.TTF");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: PINGFANG REGULAR;
  src: url("./assets/fontFamily/PINGFANG REGULAR.TTF");
  font-weight: normal;
  font-style: normal;
}
</style>
