<template>
  <!-- 每日一练 -->
  <div class="daily" v-loading="loading">
    <!-- 顶部 -->
    <div class="top">
      <!-- 年份切换 -->
      <div class="change">
        <img src="@/assets/img/Home/daily/2.png" @click="pre" />
        <div class="day">{{ year }}年{{ month }}月</div>
        <img src="@/assets/img/Home/daily/3.png" @click="next" />
        <div class="today" @click="goToday">今日</div>
      </div>
      <div class="add">
        <span>已累计练习</span>
        <span>{{ practiceDays }}</span>
        <span>天</span>
      </div>
    </div>

    <div class="container">
      <!-- 日历 -->
      <div class="calendar">
        <div class="calendar-top">
          <div class="week" v-for="item in 2" :key="item">
            <div class="week-item" v-for="(ite, idx) in 7" :key="idx">
              {{ getWeek(idx) }}
            </div>
          </div>
        </div>
        <!-- 天 -->
        <div class="days">
          <div
            :class="{
              'days-item': true,
              'days-do': item.isDo,
              'days-check': day == item.day,
            }"
            v-for="(item, index) in days"
            :key="index"
            @click="chooseDay(item)"
          >
            <span v-if="item.isToday">今</span>
            <span v-else-if="item.isShow">{{ item.day }}</span>
          </div>
        </div>
      </div>

      <!-- 列表 -->
      <div class="list">
        <div
          :class="{
            item: true,
            'item-one': list.length == 1,
            'item-more': list.length != 1,
          }"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="item-container">
            <div class="item-box">
              <div class="item-name oneEllipsis">
                {{ item.courseName }}
              </div>
              <div class="item-time">
                <img src="@/assets/img/Home/daily/time-1.png" />
                <span>预计用时：{{ item.ksTime ? item.ksTime : 0 }}分钟</span>
              </div>
            </div>
            <div class="item-box">
              <div
                class="item-but"
                v-if="item.isCheck == 0"
                @click="toDo(item, 1)"
              >
                开始练习
              </div>
              <div
                class="item-but item-see"
                v-else-if="item.isCheck == 1"
                @click="toDo(item, 2)"
              >
                查看解析
              </div>
            </div>
          </div>
        </div>
        <img
          src="https://staticfile.xlcwx.com/m-images/yth/lzimg/energize/fnNew/q7.png"
          class="noData-img"
          v-if="list.length == 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { weekZhouEnum } from "@/api/emun";
import { Know } from "@/api/know";
const know = new Know();
// import { Study } from "@/api/studyClient";
// const studyApi = new Study();
export default {
  data() {
    return {
      date: "",
      year: "",
      month: "",
      day: "",
      nowDay: "",
      days: [],
      list: [],
      practiceDays: 0,
      loading: false,
    };
  },

  watch: {
    month: function (newMonth) {
      this.getDays(this.year, newMonth);
      this.setPlaceholder(this.year, newMonth - 1);
    },
  },
  async created() {
    this.date = new Date();
    this.year = this.date.getFullYear(); // 今年
    this.month = this.date.getMonth() + 1; // 本月
    this.day = this.date.getDate(); // 今天
    this.nowDay = this.date.getDate(); // 今天的日期
    this.days = []; // 这个月有多少天
    this.getDays(this.year, this.month);
    this.setPlaceholder(this.year, this.month - 1);
    await know.selectStudentDayNum({}).then((res) => {
      this.practiceDays = res.data;
    });
    this.getInfo();
    this.selectPracticeQuesKeCheng();
  },
  methods: {
    setZero(value) {
      return value < 10 ? "0" + value : value;
    },
    // 我的课程
    getDays(year, month) {
      this.days = [];
      const haveDays = new Date(year, month, 0).getDate();
      console.log(haveDays, "haveDays");

      for (let i = 0; i < haveDays; i++) {
        this.days.push({
          day: i + 1, // 几号
          isDo: false, // 是否做题
          isShow: true, // 是否显示
          isClick: true, // 是否可以点击
        });
        if (
          year == this.date.getFullYear() &&
          month == this.date.getMonth() + 1
        ) {
          // 本年本月
          if (this.date.getDate() == i + 1) {
            // 今天
            this.days[this.days.length - 1].isToday = true;
          } else {
            this.days[this.days.length - 1].isToday = false;
          }
        }
      }
      console.log(this.days, "days");
    },
    // 查找这个月1号星期几，在days数组开头添加占位
    setPlaceholder(year, month) {
      const oneDay = new Date(year, month, 1).getDay();
      for (let i = 0; i < oneDay; i++) {
        this.days.unshift({
          isShow: false, // 是否显示
          isClick: false, // 是否可以点击
        });
      }
    },
    // 返回星期几
    getWeek(val) {
      return weekZhouEnum[val];
    },

    // 上一个月
    pre() {
      if (this.month == 1) {
        this.year--;
        this.month = 12;
      } else {
        this.month--;
      }
      this.getInfo();
    },
    // 下一个月
    next() {
      if (this.month == 12) {
        this.year++;
        this.month = 1;
      } else {
        this.month++;
      }
      this.getInfo();
    },
    // 跳转今日
    goToday() {
      this.day = this.date.getDate();
      let isChangeYearMonth = false; // 是否修改年月
      if (this.year != this.date.getFullYear()) {
        this.year = this.date.getFullYear();
        isChangeYearMonth = true;
      }
      if (this.month != this.date.getMonth() + 1) {
        this.month = this.date.getMonth() + 1;
        isChangeYearMonth = true;
      }
      if (isChangeYearMonth) {
        this.getInfo();
      } else {
        const item = this.days.find((i) => i.isToday);
      }
      this.selectPracticeQuesKeCheng();

    },
    // 选择日期
    chooseDay(item) {
      if (!item.isClick) return;
      this.day = item.day;
      this.selectPracticeQuesKeCheng();
    },
    // 做题 type 1做题2解析
    toDo(item, type) {
      this.$router.push({
        path: "/seventhPage/practiceDailyDoQuestion",
        query: {
          belongCourseTitleId: item.courseId,
          belongCourseTitleName: item.courseName,
          day: item.practiceDay,
          type,
          expectTime: item.ksTime ? item.ksTime : 0,
        },
      });
    },
    async getInfo() {
      this.loading = true;
      const res = await know.selectPracticeTimeTable({
        startTime: `${this.year}-${this.setZero(this.month)}-01`,
        endTime: `${this.year}-${this.setZero(this.month)}-${this.days.length}`,
      });
      this.loading = false;
      if (res.code != 0) {
        return;
      }
      // 练习列表
      const arr = res.data;
      let listDate = "";
      for (let i = 0; i < arr.length; i++) {
        listDate = new Date(arr[i].date);
        const index = this.days.findIndex(
          (ite) => ite.day == listDate.getDate()
        );
        if (index == -1) continue;
        this.days[index].isDo = arr[i].isMarker; // 是否全部做完
      }
    },
    async selectPracticeQuesKeCheng() {
      const res = await know.selectPracticeQuesKeCheng({
        practiceDay: `${this.year}-${this.setZero(this.month)}-${this.setZero(
          this.day
        )}`,
      });
      this.list = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.daily {
  width: 101%;
  height: 100%;
   
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto; 
}

.top {
  width: 100%;
  height: 55px;
  //   background: linear-gradient(180deg, #f3f7ff 0%, #d9e4ff 100%);
  display: flex;
  align-items: center;
  //   justify-content: center;
  position: relative;

  .change {
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .day {
      width: 115px;
      font-size: 20px;
      color: #333333;
      margin: 0 20px;
      font-weight: bold;
      text-align: center;
    }

    .today {
      width: 60px;
      height: 25px;
      text-align: center;
      line-height: 25px;
      background: #eeeeee;
      border-radius: 13px;
      font-size: 14px;
      color: #375080;
      margin-left: 12px;
      cursor: pointer;
    }
  }

  .add {
    font-size: 16px;
    color: #333333;
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: text-bottom;

    span:nth-child(2) {
      font-size: 30px;
      color: #1C81F5;
      font-weight: bold;
      margin: 0 5px;
    }
  }
}

.container {
  //   padding: 24px;

  .calendar-top {
    width: 840px;
    height: 46px;
    background: #DBEDFF;
    position: relative;
    z-index: 99;
    // background-image: url("https://staticfile.xlcwx.com/m-images/yth/lzimg/energize/fnNew/q9.png");
    background-size: 100% 100%;
    display: flex;
    padding: 0 4px;
    border-radius: 6px 6px 0 0 ;
    margin-left: 4px;

    .week {
      height: 100%;
      display: flex;

      .week-item {
        width: 60px;
        height: 100%;
        line-height: 46px;
        text-align: center;
        font-size: 14px;
        color: #333;
      }
    }
  }

  .days {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    margin-top: -5px;

    .days-item {
      width: 60px;
      height: 74px;
      text-align: center;
      line-height: 74px;
      font-size: 18px;
      color: #777;
      border-bottom: 1px solid #eee;
      border-left: 1px solid #eee;
      cursor: pointer;

      &:nth-child(14n) {
        border-right: 1px solid #eee;
      }

      &:nth-child(28) {
        border-radius: 0 0 8px 0;
      }

      &:last-child {
        border-right: 1px solid #eee;
        border-radius: 0 0 8px 0;
      }
    }

    .days-do {
      color: #1C81F5;
    }

    .days-check {
      background: #1C81F5;
      color: #fff;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;

    .item {
      height: 126px;
      border-radius: 14px;
        background-color: #d7edfe;
      background: linear-gradient(  #CEECFC 0%, #CDEAFC 65%, #7EC8FF 100%);

      padding: 8px;
      margin-bottom: 16px;
    }

    .item-container {
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px; 
      .item-box {
        display: flex;
        flex-direction: column;
        // align-items: center;

        .item-name {
          flex: 1;
          font-size: 16px;
          color: #333;
          font-weight: bold;
          margin-right: 10px;
        }

        .item-time {
          flex: 1;
          font-size: 14px;
          color: #777;
          display: flex;
          align-items: center;
          margin-top: 25px;
          margin-right: 10px;

          img {
            width: 15px;
            height: 15px;
            margin-right: 6px;
          }
        }

        .item-but {
          width: 100px;
          height: 36px;
          border-radius: 18px;
          font-size: 15px;
          background: #1C81F5;
        //  background: linear-gradient(  #CEECFC 0%, #CDEAFC 65%, #7EC8FF 100%);

          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          //   margin-top: 18px;
          cursor: pointer;
        }

        .item-see {
          border: 1px solid #1C81F5;
          color: #1C81F5;
          background: transparent;
        }

        .item-no {
          background: #f5f7f9;
          color: #aaa;
        }
      }
    }

    .item-one {
      width: 100%;

      .item-container {
        padding: 18px 28px;
        background: #fff;
        // background-image: url("~@/assets/img/Home/daily/item-bg.png");
      }
    }

    .item-more {
      width: 410px;

      .item-container {
        padding: 18px;
        background: #fff;

        // background-image: url("~@/assets/img/Home/daily/item-bg-2.png");
      }
    }
  }

  .noData-img {
    display: block;
    width: 375px;
    height: 150px;
    margin: 81px auto 0;
  }
}
</style>
